<script>
import Layout from "../../layouts/main";
import Multiselect from "vue-multiselect";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useHttp from '@/comp-functions/useHttp'
import {formatCurrency, formatNumber} from '@/utils/formatter'
import { mapKeys, camelCase } from 'lodash'
import { ref, onMounted } from '@vue/composition-api'
import router from "@/router"
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@/libs/vee-validate'
import Swal from "sweetalert2"

const DETAIL_ORDER = {
  oddGoodstypecode: 'GDTGNC', 
  goodsType: {},
  oddPackingtypecode: 'PACKRG', 
  packingType: {},
  oddCollies: '', 
  oddWeightPerColly: '',
  oddWidth: '', 
  oddHeight: '', 
  oddLength: '', 
  oddVolumePerColly: 0,
  oddChargeweightactual: 0,
  oddChargeweightround: 0,
  oddFreightcharge:0,
  oddSurchargepct:0,
  oddSurchargeamount:0,
  oddHandlingcharge:0,
  oddTotalcharge:0,
  oddRemarks: ''
  }
  /*const ORDER_CLEAR = {
      ordCollies: 0,
      ordWeightactual: 0,
      ordWeightcharge: 0,
      ordWeight: 0,
      ordFreightcharge:0,
      ordSurcharge:0,
      ordHandlingcharge:0,
      ordHandlingdgcharge:0,
      ordDiscountpct:0,
      ordDiscountamount:0,
      ordVatcharge:0,
      ordGtotalcharge:0,
      ordShipperName: '',
      ordShipperAddress: '',
      ordShipperPhone: '',
      ordConsigneeName: '',
      ordConsigneeAddress: '',
      ordConsigneePhone: '',
      details: []
  }*/

export default {
  page: {
    title: "Order Verification",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    //DatePicker,
    ValidationProvider,
    ValidationObserver,
    Multiselect,
    Layout,
    PageHeader,
  },
  props:{
      orderid: String 
  },
  data() {
    return {
      title: "Order Verification",
      items: [],
      select_label: "Select"
    };
  },
  setup(props){
    const { $put } = useHttp()
    const detailRef = ref(null)
    const masterRef = ref(null)
    const refoddCollies = ref(null)
    const refoddWeightPerColly = ref(null)
    const vendorConfig = ref(null)
    const { $get } = useHttp()
    const selectedSchedule = ref({})
    const configDropship = ref({})
    const orderRules = ref({})
    const maxcargodimentioncm = ref({})
    const bUpdatedetail = ref(false)
    const noRowDetail = ref()
    const idxUpdatedtl = ref(-1)
    const ordgtamtold = ref(0)
    const LOV = ref({
      goodsType: [],
      addressBook:[],
      packingType:[],
    })
    const form = ref({ordGtotalcharge:0})
    // const formold = ref({ordGtotalcharge:0})
    // const formdetailold = ref({})

    const currentDetailOrder = ref({...DETAIL_ORDER})

    const sumChargeableWeight = () =>{
      form.value.ordWeightactual = form.value.details.reduce((accumulator, curr) => accumulator + (curr.oddWeightPerColly*curr.oddCollies), 0)
      form.value.ordWeightcharge = form.value.details.reduce((accumulator, curr) => accumulator + (curr.oddChargeweightactual*curr.oddCollies), 0)
      form.value.ordWeight = form.value.details.reduce((accumulator, curr) => accumulator + (curr.oddChargeweightround*curr.oddCollies), 0)
      form.value.ordCollies = form.value.details.reduce((accumulator, {oddCollies}) => (accumulator + parseInt(oddCollies)), 0)
      // form.value.ordercharges = []
      // let ordercharge = {
      //   ochChargetypecode: 'OCHMMA', 
      //   ochChargetypename: 'Admin', 
      //   ochChargepct: 0, 
      //   ochChargeamount: 15000
      // }
      // form.value.ordercharges.push({...ordercharge})
      form.value.ordFreightcharge = form.value.details.reduce((accumulator, curr) => accumulator + (curr.oddFreightcharge*curr.oddCollies), 0)
      //form.value.ordFreightcharge = form.value.ordWeight * selectedSchedule.value.price_kg
      // ordercharge = {
      //   ochChargetypecode: 'OCHMMA', 
      //   ochChargetypename: 'Freight', 
      //   ochChargepct: 0,
      //   ochChargeamount: form.value.ordFreightcharge
      // }
      // form.value.ordercharges.push({...ordercharge})
      
      form.value.ordHandlingcharge = form.value.details.reduce((accumulator, curr) => accumulator + (parseInt(curr.oddHandlingcharge)*parseInt(curr.oddCollies)), 0)
      form.value.ordSurcharge = form.value.details.reduce((accumulator, curr) => accumulator + (parseInt(curr.oddSurchargeamount)*parseInt(curr.oddCollies)), 0)
      let gtotalbeforvat = parseInt(form.value.ordFreightcharge) + parseInt(form.value.ordSurcharge) + parseInt(form.value.ordHandlingcharge) - parseInt(form.value.ordDiscountamount) + parseInt(form.value.ordAdminfee)
      form.value.ordVatcharge = Math.round(gtotalbeforvat * 0.01)
      form.value.ordGtotalcharge = gtotalbeforvat + form.value.ordVatcharge
    }

    const getSchedule = async () => {
      const {data} = await $get({ url: 'master/schedule_v2/' + form.value.ordScheduleId})
      selectedSchedule.value = data
      getVendorconfig()
    }

    const getOrder = async () => {
      if (typeof props.orderid !== 'undefined'){
        const {data} = await $get({ url: 'master/order/' + props.orderid})
        let dataCase = mapKeys(data, (val, key) => camelCase(key))
        dataCase.details = dataCase.orderDetail.map(prop => {
          // prop.goodsType = mapKeys(prop.goodsType, (val, key) => camelCase(key))
          return mapKeys(prop, (val, key) => camelCase(key))
        })
        form.value = {...dataCase}
        ordgtamtold.value = dataCase.ordGtotalcharge
        // formold.value = {...dataCase}
        if(typeof form.value.ordScheduleId !== 'undefined'){
          getSchedule()
        }
      }
    }
    // const getOrderold = async () => {
    //   if (typeof props.orderid !== 'undefined'){
    //     const {data} = await $get({ url: 'master/order/' + props.orderid})
    //     let dataCase2 = mapKeys(data, (val, key) => camelCase(key))
    //     dataCase2.details = dataCase2.orderDetail.map(prop => {
    //       // prop.goodsType = mapKeys(prop.goodsType, (val, key) => camelCase(key))
    //       return mapKeys(prop, (val, key) => camelCase(key))
    //     })
    //     formold.value = {...dataCase2}
    //   }
    // }
    const getVendorconfig = async () => {
      const {data} = await $get({ url: 'master/vendorconfigall/'+selectedSchedule.value.sch_vendor_id+'/'+selectedSchedule.value.sch_origin_port_id+'/'+selectedSchedule.value.sch_aircraft_type_code})
      orderRules.value = data.orderrule.vnc_configs.goods_validation
      configDropship.value = data.dropship.vnc_configs.dropship
      maxcargodimentioncm.value  = data.orderrule.aircrafttype.value.maxcargodimentioncm
      form.value.ordAdminfee = orderRules.value.adminfee
      vendorConfig.value = data
      getGoodsType()
    }

    const getPackingType = async () => { 
      const {data} = await $get({ url: 'master/codes/category/PAC'})
      LOV.value.packingType = data
    }

    const getAddressBook = async () => { 
      const {data} = await $get({ url: 'master/address_books'})
      LOV.value.addressBook = data
    }

    const getGoodsType = async () => {
      const {data} = await $get({ url: 'master/codes/category/GDT'})
      let goodstypeallowlist = []
      let goodstypeallowtemp = {}
      orderRules.value.surcharge_goodstype.map((value) => {
          goodstypeallowtemp = data.filter(
            data => data["lbc_code"] === value.goodstype
          )
          if(goodstypeallowtemp.length > 0) goodstypeallowlist.push(...goodstypeallowtemp)
      })
      LOV.value.goodsType = goodstypeallowlist
    }

    const clearDetail = async ()=> {
      if(bUpdatedetail.value){
        
        const {data} = await $get({ url: 'master/order/' + form.value.ordId})
        let dataCase = mapKeys(data, (val, key) => camelCase(key))
        dataCase.details = dataCase.orderDetail.map(prop => {
          // prop.goodsType = mapKeys(prop.goodsType, (val, key) => camelCase(key))
          return mapKeys(prop, (val, key) => camelCase(key))
        })
        
        const dtledit = dataCase.details.filter(
          data => data["oddId"] ===  form.value.details[idxUpdatedtl.value].oddId
        )
        form.value.details[idxUpdatedtl.value] = {...dtledit[0]}
      }
      currentDetailOrder.value = {...DETAIL_ORDER}
      selectedGoodsType.value = {}
      selectedPackingType.value = {}
      detailRef.value.reset()
      bUpdatedetail.value = false
      idxUpdatedtl.value = -1
      // formdetailold.value = {}
    }
    const postForm = async () => {
      if(bUpdatedetail.value){
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: 'You are currently editing the details of goods a shipment, please click update or clear the data first',
          showConfirmButton: true,
          timer: 2600,
          timerProgressBar: true
        });
        return false
      }
      if(validationDetail()){
        const validated = await masterRef.value.validate()
        if(validated){
          const response = await $put({ url: 'master/orderv2/update',
            data: form.value
          })
          if(response.status === 'success'){
            router.push({name: 'apps-billing-invoiceadjustment', params: {orderid : form.value.ordId}})
          }else if(response.status === 'failed'){
            Swal.fire({
              position: "top-end",
              icon: "error",
              title: response.messages,
              showConfirmButton: true,
              timer: 3500,
              timerProgressBar: true
            });
          }
        }
      }
    }

    const resetForm = async ()=> {
        const {data} = await $get({ url: 'master/order/' + form.value.ordId})
        let dataCase = mapKeys(data, (val, key) => camelCase(key))
        dataCase.details = dataCase.orderDetail.map(prop => {
          // prop.goodsType = mapKeys(prop.goodsType, (val, key) => camelCase(key))
          return mapKeys(prop, (val, key) => camelCase(key))
        })
      form.value = {...dataCase}
      masterRef.value.reset();
      bUpdatedetail.value = false
      await clearDetail()
    }

    const validationPercolly = ()=> {
      const vordWeight = isNaN(parseInt(form.ordWeight)) ? 0 : parseInt(form.ordWeight)
      if(
        parseFloat(currentDetailOrder.value.oddLength) > maxcargodimentioncm.value.length ||
        parseFloat(currentDetailOrder.value.oddWidth) > maxcargodimentioncm.value.width ||
        parseFloat(currentDetailOrder.value.oddHeight) > maxcargodimentioncm.value.height
      ){
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "The dimensions are over capacity",
          html: `The maximum length: ${maxcargodimentioncm.value.length}, width: ${maxcargodimentioncm.value.width}, height: ${maxcargodimentioncm.value.height}`,
          showConfirmButton: true,
          timer: 2600,
          timerProgressBar: true
        });
        return false
      }else if(
         parseFloat(orderRules.value.colly_actualweight_max) < parseFloat(currentDetailOrder.value.oddWeightPerColly)
        ){
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: `The Maximum of Actual/Gross Weight per colly is  ${orderRules.value.colly_actualweight_max}`,
          showConfirmButton: true,
          timer: 2600,
          timerProgressBar: true
        });
        currentDetailOrder.value.oddWeightPerColly = ''
        refoddWeightPerColly.value.focus()
        return false
      }else if(parseInt(orderRules.value.total_collies_max) < parseInt(currentDetailOrder.value.oddCollies)){
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: `The maximum number of collies are ${orderRules.value.total_collies_max}`,
          showConfirmButton: true,
          timer: 2600,
          timerProgressBar: true
        });
        currentDetailOrder.value.oddCollies = ''
        refoddCollies.value.focus()
        return false
      }else if(
        parseInt(orderRules.value.total_actualweight_max) < 
          (vordWeight + (currentDetailOrder.value.oddChargeweightround * parseInt(currentDetailOrder.value.oddCollies))) 
        ){
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: `The maximum total weight per order are ${orderRules.value.total_actualweight_max}`,
          showConfirmButton: true,
          timer: 2600,
          timerProgressBar: true
        });
        return false
      }else{
        return true
      }
    }

    const validationDetail = ()=> {
     if(
       form.value.details.length<1 ||
       form.value.details === null
      ){
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "There is no details found",
          html: `You must add details`,
          showConfirmButton: true,
          timer: 2600,
          timerProgressBar: true
        });
        return false
      }else{
        return true
      }
    }

    const calculateWeight = ()=> {
      currentDetailOrder.value.oddVolumePerColly = (currentDetailOrder.value.oddLength * currentDetailOrder.value.oddWidth * currentDetailOrder.value.oddHeight)/6000
      if(currentDetailOrder.value.oddVolumePerColly  > currentDetailOrder.value.oddWeightPerColly){
        currentDetailOrder.value.oddChargeweightactual = currentDetailOrder.value.oddVolumePerColly
      }else{
        currentDetailOrder.value.oddChargeweightactual = currentDetailOrder.value.oddWeightPerColly
      }
      if(currentDetailOrder.value.oddChargeweightactual < orderRules.value.colly_chargeableweight_min){
        currentDetailOrder.value.oddChargeweightactual = orderRules.value.colly_chargeableweight_min
      }
      currentDetailOrder.value.oddChargeweightround = Math.ceil(currentDetailOrder.value.oddChargeweightactual)
      validationPercolly()
    }

    const selectConsignee = (selectedOption)=> {
      form.value.ordConsigneeName = selectedOption.consignee_name
      form.value.ordConsigneeAddress = selectedOption.consignee_address
      form.value.ordConsigneePhone = selectedOption.consignee_phone
    }
    const addRow = async () => {
      const validated = await detailRef.value.validate()
      if (validated) {
        if(validationPercolly()){
        // let detailcharge = {
        //   odcChargetypecode: 'OCHMMA', 
        //   odchChargetypename: 'Admin1', 
        //   odcChargepct: 0, 
        //   odcChargeamountperkg: 0,
        //   odcChargeamountpercolly: 0,
        //   odcChargeamountperttl: 15000
        // }
        //currentDetailOrder.value.oddChargearr.push({...detailcharge})
          calculateWeight()
          currentDetailOrder.value.oddFreightcharge = parseInt(currentDetailOrder.value.oddChargeweightround) * selectedSchedule.value.sch_price_kg
          
          const data_surcharge_goodstype = orderRules.value.surcharge_goodstype.filter(
            data => data["goodstype"] === selectedGoodsType.value.lbc_code
          )
          if(data_surcharge_goodstype.length > 0 ){
            currentDetailOrder.value.oddSurchargepct = data_surcharge_goodstype[0].extchargingpercent
          }
          const data_surcharge_heavy = orderRules.value.surcharge_heavy.filter(
            data => parseInt(data["minkg"]) <= Math.ceil(currentDetailOrder.value.oddWeightPerColly) && 
                    parseInt(data["maxkg"]) >= Math.ceil(currentDetailOrder.value.oddWeightPerColly)
          )
          if(data_surcharge_heavy.length > 0){
            if(data_surcharge_heavy[0].extchargingpercent > currentDetailOrder.value.oddSurchargepct){
              currentDetailOrder.value.oddSurchargepct = data_surcharge_heavy[0].extchargingpercent
            }
          }
          if(typeof orderRules.value.surcharge_heavy_handling !== 'undefined'){
            const data_surcharge_heavy_handling = orderRules.value.surcharge_heavy_handling.filter(
              data => parseInt(data["minkg"]) < Math.ceil(currentDetailOrder.value.oddWeightPerColly) && 
                      parseInt(data["maxkg"]) >= Math.ceil(currentDetailOrder.value.oddWeightPerColly)
            )
            if(data_surcharge_heavy_handling.length > 0){
              currentDetailOrder.value.oddHandlingcharge = data_surcharge_heavy_handling[0].chargeamount
            }
          }else{
            currentDetailOrder.value.oddHandlingcharge = 0
          }
          currentDetailOrder.value.oddSurchargeamount = currentDetailOrder.value.oddFreightcharge * currentDetailOrder.value.oddSurchargepct / 100
          currentDetailOrder.value.oddTotalcharge = ((currentDetailOrder.value.oddFreightcharge + parseInt(currentDetailOrder.value.oddSurchargeamount) + parseInt(currentDetailOrder.value.oddHandlingcharge)) * currentDetailOrder.value.oddCollies)
          if(form.value.details.length > 1 || (form.value.details.length === 0 && !bUpdatedetail)){
            if(form.value.ordSurcharge > 0 && currentDetailOrder.value.oddSurchargeamount === 0){
              Swal.fire({
                position: "top-end",
                icon: "error",
                title: `Special Cargo cannot be mixed with General Cargo`,
                showConfirmButton: true,
                timer: 2600,
                timerProgressBar: true
              });
              return false
            }else if(form.value.ordSurcharge === 0 && currentDetailOrder.value.oddSurchargeamount >  0){
              Swal.fire({
                position: "top-end",
                icon: "error",
                title: `General Cargo cannot be mixed with Special Cargo`,
                showConfirmButton: true,
                timer: 2600,
                timerProgressBar: true
              });
              return false
            }
          }
          if(bUpdatedetail.value && currentDetailOrder.value.oddId){
            // form.value.details.map(function(value, key) {
            //   if (value.oddId === currentDetailOrder.value.oddId) { idx =  key }
            // });
            console.log(idxUpdatedtl.value)
            if(idxUpdatedtl.value >= 0){
              console.log(idxUpdatedtl.value)
              form.value.details[idxUpdatedtl.value] = {
                ...currentDetailOrder.value,
                oddGoodstypecode: selectedGoodsType.value.lbc_code,
                goodsType : selectedGoodsType.value,
                oddPackingtypecode: selectedPackingType.value.lbc_code,
                packingType: selectedPackingType.value
              }
            }
            bUpdatedetail.value = false
          }else{
            form.value.details.push({
              ...currentDetailOrder.value,
              oddGoodstypecode: selectedGoodsType.value.lbc_code,
              goodsType : selectedGoodsType.value,
              oddPackingtypecode: selectedPackingType.value.lbc_code,
              packingType: selectedPackingType.value,
            })
          }
          await clearDetail()
          // detailsLength.value = form.value.details.length +1
          sumChargeableWeight()
        }
        detailRef.value.reset();
      }
    }

    const editRow =  (index) => {
      idxUpdatedtl.value = index
      console.log(idxUpdatedtl.value)
      const temp = form.value.details[index]
      // formdetailold.value = temp
      currentDetailOrder.value = temp
      selectedGoodsType.value = temp.goodsType
      selectedPackingType.value = temp.packingType
      bUpdatedetail.value = true
      noRowDetail.value = index+1
    }
    const removeRow =  (index) => {
      if (form.value.details.length <= 0) return false
      form.value.details.splice(index, 1)
      // detailsLength.value = form.value.details.length +1
      sumChargeableWeight()

    }

    // const detailsLength = ref(form.value.details.length+1)
    const selectedGoodsType = ref(null)
    const selectedPackingType = ref(null)
    const selectedAddressBook = ref({consignee_name:'Please', consignee_address:'Click', consignee_phone:'to Select'})

    onMounted(() => {
     getOrder()
    //  getOrderold()
     getPackingType()
     getAddressBook()
    })
 
 return{
   ordgtamtold,
  postForm,
  noRowDetail,
  bUpdatedetail,
  refoddCollies,
  refoddWeightPerColly,
   required,
   detailRef,
   masterRef,
   selectedSchedule,
   orderRules, configDropship, maxcargodimentioncm, vendorConfig,
   LOV,
   form,
  //  formold,
   clearDetail,
   resetForm,
   currentDetailOrder,
   calculateWeight,
   addRow,
   editRow,
   removeRow,
  //  detailsLength,
   sumChargeableWeight,
   selectedGoodsType,
   selectedPackingType,
   selectedAddressBook,
   selectConsignee,
  formatCurrency,
  formatNumber
 }
  },
  methods: {
    customLabelConsignee ({ consignee_name, consignee_address, consignee_phone }) {
      return `${consignee_name} – ${consignee_address} – ${consignee_phone}`
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row" v-if="typeof selectedSchedule.vnd_name !== 'undefined'">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-xl-3">
                {{selectedSchedule.vnd_name}} - {{selectedSchedule.sch_flight_no}} <br/>
                Departure {{selectedSchedule.sch_departure_date}}
              </div>
              <div class="col-xl-3 text-center">
                {{selectedSchedule.sch_departure_time}} <i class="ri-arrow-right-line"></i> {{selectedSchedule.sch_arrival_time}}<br/>
                {{selectedSchedule.oriprt_locationname}} ({{selectedSchedule.oriprt_initial}}) <i class="ri-arrow-right-line"></i> {{selectedSchedule.desprt_locationname}} ({{selectedSchedule.desprt_initial}})
              </div>
              <div class="col-xl-3 text-center">
                {{Math.floor(selectedSchedule.sch_duration/60)}}h {{Math.floor(selectedSchedule.sch_duration%60)}}m<br/>
                <div v-if="selectedSchedule.sch_transit > 0">{{selectedSchedule.sch_transit}} Transit</div>
                    <div v-else>Direct</div>
              </div>
              <div class="col-xl-3 text-center">
                <h5 class="mb-0">{{ formatCurrency(selectedSchedule.sch_price_kg) }}/kg</h5>
                <div>Available <span class="font-size-20 font-weight-bold">{{ formatNumber(selectedSchedule.sch_availablecapacity) }}kg</span></div>
              </div>               
            </div>
            <br/>
            <div class="row">
              <div class="col-md-10">
                Notes : Goods must be in <u class="font-weight-bold">{{configDropship.locationname}}</u> a 
                maximum of <u class="font-weight-bold">{{configDropship.limithour}} hours</u> before departure time
              </div>
              <div class="col-md-2 text-right">
                  <a href="#" class="btn btn-sm btn-info" v-b-modal.modal-flightdetail >
                    Flight Details
                  </a>
              </div>          
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <form class="form-horizontal" role="form">
              <b-form-group
                label-cols-sm="2"
                label-cols-lg="2"
                label="Order No"
                label-for="order_no"
              >
                <b-form-input v-model="form.ordNo" disabled></b-form-input>
              </b-form-group>
              <b-form-group
                label-cols-sm="2"
                label-cols-lg="2"
                label="SMU No"
                label-for="order_no"
              >
                <b-form-input v-model="form.ordManifestno" disabled></b-form-input>
              </b-form-group>
            </form>
            <hr/>
              <validation-observer ref="detailRef">
                <b-form @submit.prevent>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group mb-3">
                      <label>Goods Type <span class="text-danger">*</span></label><br />
                      <validation-provider
                        #default="{ errors }"
                        name="goodsType"
                        rules="required"
                      >
                        <multiselect v-model="selectedGoodsType" :options="LOV.goodsType" label="descr_id" track-by="descr_id" :select-label="select_label" ></multiselect>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </div>
                    <div class="form-group mb-3">
                      <label>Actual Weight Per Colly <span class="text-danger">*</span></label><br />
                      <validation-provider
                        #default="{ errors }"
                        name="actualWeight"
                        rules="required"
                      >
                        <b-form-input @blur="calculateWeight()" ref="refoddWeightPerColly" v-model="currentDetailOrder.oddWeightPerColly"></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </div>
                    <div class="row">
                      <div class="col-md-4 form-group">
                        <label>Length (CM) <span class="text-danger">*</span></label><br />
                        <validation-provider
                        #default="{ errors }"
                        name="length"
                        rules="required"
                      >
                        <b-form-input @blur="calculateWeight()" v-model="currentDetailOrder.oddLength"></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                      </div>
                      <div class="col-md-4 form-group">
                        <label>Width (CM) <span class="text-danger">*</span></label><br />
                          <validation-provider
                            #default="{ errors }"
                            name="width"
                            rules="required"
                          >
                            <b-form-input @blur="calculateWeight()" v-model="currentDetailOrder.oddWidth"></b-form-input>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                      </div>
                      <div class="col-md-4 form-group">
                        <label>Height (CM) <span class="text-danger">*</span></label><br />
                        <validation-provider
                        #default="{ errors }"
                        name="height"
                        rules="required"
                      >
                        <b-form-input @blur="calculateWeight()" v-model="currentDetailOrder.oddHeight"></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                        
                      </div>
                    </div>
                    
                    <div class="row">
                      <div class="col-md-6 form-group">
                        <label>Volume Per Colly</label><br />
                        <b-form-input readonly v-model="currentDetailOrder.oddVolumePerColly"></b-form-input>
                      </div>
                      <div class="col-md-6 form-group">
                        <label>Chargeable Weight Per Colly </label><br />
                        <b-form-input readonly v-model="currentDetailOrder.oddChargeweightround"></b-form-input>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group mb-3">
                      <label>Collies<span class="text-danger">*</span></label><br />
                      <validation-provider
                        #default="{ errors }"
                        name="collies"
                        rules="required"
                      >
                        <b-form-input @blur="calculateWeight()" ref="refoddCollies" v-model="currentDetailOrder.oddCollies"></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </div>
                    <div class="form-group mb-3">
                      <label>Packing <span class="text-danger">*</span></label><br />
                      <validation-provider
                        #default="{ errors }"
                        name="goodsPacking"
                        rules="required"
                      >
                        <multiselect v-model="selectedPackingType" :options="LOV.packingType" label="descr_id" track-by="descr_id" :select-label="select_label" ></multiselect>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </div>
                    <div class="form-group mb-3">
                      <label>Remarks</label><br />
                      <b-form-input v-model="currentDetailOrder.oddRemarks"></b-form-input>
                    </div>
                  </div>
                  <div class="col-12 text-center">
                    <hr/>
                    <b-button v-if="!bUpdatedetail" variant="primary" @click="addRow()" size="sm">
                      <i class="ri-add-fill"></i>
                      Add
                    </b-button>
                    <b-button v-if="bUpdatedetail" variant="primary" @click="addRow()" size="sm">
                      <i class="ri-save-line"></i>
                      Update row - {{noRowDetail}}
                    </b-button>
                    &nbsp;
                    <b-button variant="danger" @click="clearDetail()" size="sm">
                      <i class="mdi mdi-refresh align-middle"></i>
                      Clear
                    </b-button>
                  </div>
                  </div>
                </b-form>
              </validation-observer>
              <div class="row">
              <div class="col-12">
                <hr/>
                  <div class="table-responsive  mb-2 mt-2">
                    <table class="table font-size-13 table-bordered">
                      <thead>
                        <tr>
                          <th>No.</th>
                          <th>Goods Type</th>
                          <th>Collies</th>
                          <th>Actual Weight</th>
                          <th>Volume</th>
                          <th>Chargeable Weight</th>
                          <th>Packing </th>
                          <th>Remarks</th>
                          <th>Additional Charge</th>
                          <th>Total Charge</th>
                          <th>#</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(detailOrder,index) in form.details" :key="index">
                          <td scope="row">{{index+1}}</td>
                          <td>{{detailOrder.goodsType.descr_en}}</td>
                          <td class="text-right">{{detailOrder.oddCollies}}</td>
                          <td class="text-right">{{detailOrder.oddWeightPerColly}}</td>
                          <td class="text-right">{{detailOrder.oddVolumePerColly}}</td>
                          <td class="text-right">{{detailOrder.oddChargeweightround}}</td>
                          <td>{{detailOrder.packingType.descr_en}}</td>
                          <td>{{detailOrder.oddRemarks}}</td>
                          <td class="text-right">{{formatNumber(parseInt(detailOrder.oddSurchargeamount)+parseInt(detailOrder.oddHandlingcharge))}}</td>
                          <td class="text-right">{{formatNumber(detailOrder.oddTotalcharge)}}</td>
                          <td class="text-center">
                            <b-button v-if="typeof detailOrder.oddId !== 'undefined'" size="sm" variant="light" @click="editRow(index)">
                              <i class="ri-edit-2-line"></i>
                            </b-button>&nbsp;
                            <b-button size="sm" variant="danger" @click="removeRow(index)">
                              <i class="ri-delete-bin-line"></i>
                            </b-button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->


    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <validation-observer ref="masterRef">
                <b-form @submit.prevent>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group mb-3">
                        <label>Shipper Name <span class="text-danger">*</span></label><br />
                        <validation-provider
                        #default="{ errors }"
                        name="shippername"
                        rules="required"
                        >
                          <b-form-input v-model="form.ordShipperName"></b-form-input>
                          <small class="text-danger">{{ errors[0] }}</small>
                         </validation-provider>
                      </div>
                      <div class="form-group mb-3">
                        <label>Shipper Address <span class="text-danger">*</span></label><br />
                        <validation-provider
                        #default="{ errors }"
                        name="shipperaddress"
                        rules="required"
                        >
                          <b-form-input v-model="form.ordShipperAddress"></b-form-input>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                      <div class="form-group mb-3">
                        <label>Shipper Phone <span class="text-danger">*</span></label><br />
                        <validation-provider
                        #default="{ errors }"
                        name="shipperphone"
                        rules="required"
                        >
                          <b-form-input v-model="form.ordShipperPhone"></b-form-input>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group mb-3 hidden">
                        <label>Find Consignee</label><br />
                        <multiselect :select-label="select_label"  placeholder="Click to Select" v-model="selectedAddressBook" :options="LOV.addressBook" :custom-label="customLabelConsignee" @select="selectConsignee"></multiselect>
                      </div>
                      <div class="form-group mb-3">
                        <label>Consignee Name <span class="text-danger">*</span></label><br />
                        <validation-provider
                        #default="{ errors }"
                        name="consigneename"
                        rules="required"
                        >
                          <b-form-input v-model="form.ordConsigneeName"></b-form-input>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                      <div class="form-group mb-3">
                        <label>Consignee Address <span class="text-danger">*</span></label><br />
                        <validation-provider
                        #default="{ errors }"
                        name="consigneeaddress"
                        rules="required"
                        >
                          <b-form-input v-model="form.ordConsigneeAddress"></b-form-input>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                      <div class="form-group mb-3">
                        <label>Consignee Phone <span class="text-danger">*</span></label><br />
                        <validation-provider
                        #default="{ errors }"
                        name="consigneephone"
                        rules="required"
                        >
                          <b-form-input v-model="form.ordConsigneePhone"></b-form-input>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                    </div>
                  </div>
                </b-form>
              </validation-observer>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-body mb">
                <b-form-group
                  label-cols-sm="5"
                  label-cols-lg="5"
                  label="Total Chargeable Weight"
                  label-for=""
                >
                  <div class="pt-2 text-right"><h6>{{form.ordWeight}}</h6></div>
                </b-form-group>
                <b-form-group
                  label-cols-sm="5"
                  label-cols-lg="5"
                  label="Total Freight Charges"
                  label-for=""
                >
                  <div class="pt-2 text-right"><h6>{{ formatNumber(form.ordFreightcharge) }}</h6></div>
                </b-form-group>
                <b-form-group
                  label-cols-sm="5"
                  label-cols-lg="5"
                  label="Total Surcharge"
                  label-for=""
                >
                  <div class="pt-2 text-right"><h6>{{ formatNumber(form.ordSurcharge) }}</h6></div>
                </b-form-group>
                <b-form-group
                  label-cols-sm="5"
                  label-cols-lg="5"
                  label="Total Handling Heavy Charge"
                  label-for=""
                >
                  <div class="pt-2 text-right"><h6>{{ formatNumber(form.ordHandlingcharge) }}</h6></div>
                </b-form-group>
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->

      <div class="col-md-6">
        <div class="card">
          <div class="card-body">
            
                <b-form-group
                  label-cols-sm="5"
                  label-cols-lg="5"
                  label="Handling DG Charge"
                  label-for=""
                >
                  <div class="pt-2 text-right"><h6>{{ formatNumber(form.ordHandlingdgcharge) }}</h6></div>
                </b-form-group>
                <b-form-group
                  label-cols-sm="5"
                  label-cols-lg="5"
                  label="Admin SMU"
                  label-for=""
                >
                  <div class="pt-2 text-right"><h6>{{ formatNumber(form.ordAdminfee) }}</h6></div>
                </b-form-group>
                <b-form-group
                  label-cols-sm="5"
                  label-cols-lg="5"
                  label="Admin Fee"
                  label-for=""
                >
                  <div class="pt-2 text-right"><h6>{{ formatNumber(form.ordVatcharge) }}</h6></div>
                </b-form-group>
                <b-form-group
                  label-cols-sm="5"
                  label-cols-lg="5"
                  label="Grand Total"
                  label-for=""
                >
                  <div class="pt-2 text-right"><h6>{{ formatNumber(form.ordGtotalcharge) }}</h6></div>
                </b-form-group>
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12 text-center">
                <div class="badge badge-info font-size-17">
                    Balance : {{ formatNumber(form.ordGtotalcharge-ordgtamtold) }}
                </div>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12 text-center">
                <div class="button-items">
                  <b-button variant="primary" @click="postForm">
                    <i class="ri-save-line align-middle"></i>
                    Submit
                  </b-button>
                  <b-button variant="danger" @click="resetForm">
                    <i class="mdi mdi-refresh align-middle"></i> Reset
                  </b-button>
                </div>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
    <b-modal
      id="modal-flightdetail"
      size="xl"
      title="Flight Details Information"
      title-class="font-20"
      hide-footer
    >
      <ul v-if="typeof maxcargodimentioncm.length !== 'undefined'">
        <li>
          {{orderRules.info_en}}
        </li>
        <li>
          Aircraft Type : {{vendorConfig.orderrule.aircrafttype.descr_en}}
        </li>
        <li>
          The maximum of dimension per colly :
          Length: {{maxcargodimentioncm.length}}Cm, width: {{maxcargodimentioncm.width}}Cm, height: {{maxcargodimentioncm.height}}Cm
        </li>
        <li v-if="orderRules.total_actualweight_max !== 9999999">
          The maximum of total Actual Weight : {{orderRules.total_actualweight_max}}Kg
        </li>
        <li v-if="orderRules.total_collies_max !== 9999999">
          The maximum of total Collies : {{orderRules.total_collies_max}}
        </li>
        <li>
          Surcharge for Heavy Cargo :
          <ol>
            <li v-for="(surchargeHeavy,index) in orderRules.surcharge_heavy" :key="index">
                {{surchargeHeavy.minkg}}Kg - {{surchargeHeavy.maxkg}}Kg : {{surchargeHeavy.extchargingpercent}}&percnt;
            </li>
          </ol>
        </li>
      </ul>
    </b-modal>
  </Layout>
</template>
